import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComments } from '@fortawesome/pro-regular-svg-icons'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import Animated from '../components/animated'
import Layout from '../components/layout'
import logo from '../images/logo-full.png'

const IndexPage = () => (
    <Layout>
        <Animated delay={1000}>
            <div className="border-b-4 border-purple mb-12 md:mb-24 pb-6 md:pb-12">
                <img src={logo} alt="" className="max-w-full h-12 md:h-14 block" />
            </div>
        </Animated>
        <Animated animation="fade-down" delay={1000}>
            <h1 className="text-lg md:text-xl text-white text-shadow">
                Craftsmanlike app development
            </h1>
        </Animated>
        <Animated animation="fade-down" delay={1250}>
            <div className="text-sm md:text-base text-white-60 leading-normal mt-8 mb-12 text-shadow">
                <p>
                    Globobeet is a{' '}
                    <OutboundLink
                        className="inline-block text-white hover:text-purple no-underline transition"
                        href="https://github.com/alobodig"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        development
                    </OutboundLink>{' '}
                    <OutboundLink
                        className="inline-block text-white hover:text-purple no-underline transition"
                        href="https://github.com/joshlasdin"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        duo
                    </OutboundLink>{' '}
                    with a keen eye for design and a passion for building smart, modern,
                    simple-to-use applications on all the various devices we use today.
                </p>
            </div>
        </Animated>
        <Animated animation="fade-down" delay={1500}>
            <a
                className="text-xs md:text-sm text-white font-bold rounded-lg bg-purple hover:opacity-80 shadow-lg hover:shadow-md inline-block py-3 px-6 no-underline"
                href="mailto:hello@globobeet.org"
            >
                <FontAwesomeIcon
                    icon={faComments}
                    className="text-purple-dark opacity-40 mr-4 inline-block align-middle text-lg"
                />
                Drop us a line
            </a>
        </Animated>
    </Layout>
)

export default IndexPage
